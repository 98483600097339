<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col cols="12" sm="7" md="7" lg="7" align-self="end" class="pb-1">
        <v-btn-toggle
          active-class="active-filter"
          class="px-0 filter-menu"
          borderless
          dense
          background-color="transparent"
          v-model="filter_status"
        >
          <v-btn
            text
            value="todo"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Todos({{ menus.length }})
          </v-btn>
          |
          <v-btn
            text
            value="1"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Publicadas ({{ statusCount.public }})
          </v-btn>
          |
          <v-btn
            text
            value="0"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Borrador ({{ statusCount.eraser }})
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" class="pb-1" md="5" lg="5" sm="5">
        <v-text-field
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          outlined
          rounded
          dense
          color="primary"
          placeholder="Buscar menú"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0">
          <v-sheet class="" v-if="load_dish">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>

          <v-row>
            <v-col v-for="(item, i) in menus" :key="i" md="3" for="">
              <v-card>
                <v-card-title class="text-center">{{
                  item.name.es
                }}
                </v-card-title>
                <v-card-text class="text-center">
                  <img
                    :src="storage + item.imageurl"
                    style="margin: 0 auto;display:block;height: 150px;width:100%"
                  /><br />
                  <div class="mt-4"></div>
                  <span class="font-italic blue-grey--text">{{
                    item.publication_date | smartDate
                  }}</span>
                  <h3>Platos:</h3>
                  <template v-for="(dishe, i) in item.dishes">
                    <router-link
                      :to="`/tc-admin/platos/${dishe.id}`"
                      class="text-truncate"
                      v-text="dishe.name.es"
                      :key="i"
                    ></router-link>
                    <span v-if="i < item.dishes.length - 1" :key="i + 'G'">
                      -
                    </span>
                  </template>

                </v-card-text>
                  <v-expansion-panels class="mb-2">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="text-center">
                        <span><v-icon color="blue-grey">mdi-cash</v-icon> Ver Variaciones</span>
                    </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="row" >
                        <div
                          class="col-6 text-left"
                          v-for="(p, i) in item.price"
                          :key="i"
                        >
                          <h5>{{ p.name }}:</h5>
                          <span v-for="(detail, idx) in filterPrice(p.prices)" :key="idx"
                            >
                            |{{ formatNumber(detail.amount,coinCountry[detail.coin]) }}|
                          </span>
                        </div>
                      </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>


                <v-bottom-navigation activeClass="v-btn--hover">
                  <v-btn
                    color="deep-green lighten-2"
                    text
                    large
                    @click="$router.push(url_edit + item.id)"
                    v-if="$can('Editar menu')"
                  >
                    <v-icon color="secundary">mdi-pencil</v-icon> Editar
                  </v-btn>
                  <v-btn
                    color="deep-green lighten-2"
                    text
                    large
                    @click="deletemenu(item)"
                    v-if="$can('Borrar menu')"
                  >
                    <v-icon color="secundary">mdi-trash-can-outline</v-icon>
                    Eliminar
                  </v-btn>
                </v-bottom-navigation>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import coinSymbol from "../../../mixins/coinSymbol"
import coinCountry from "../../../mixins/coinCountry"
export default {
  name: "menus",
  data() {
    return {
      snackbar: {
        text: "",
        active: false,
        type: ""
      },
      filter_status: "todo",
      search: "",
      selected_menu: [],
      load_dish: false,
      saving_user: false,
      show: false,
      menus: [],
      roles: [],
      restaurants: [],
      sort_menu: "",
      headers: [
        {
          text: "",
          value: "imageurl",
          sortable: false,
          width: "60",
          align: "center"
        },
        { text: "Nombre", value: "name.es", class: "primary--text" },
        { text: "Precio", value: "price", class: "primary--text" },
        {
          text: "Platos",
          value: "dishes",
          class: "primary--text",
          width: "400"
        },
        { text: "Fecha", value: "publication_date", class: "primary--text" },
        { text: "", value: "actions", class: "primary--text", width: "150" }
      ],
      restaurant_id: "",
      id_user: "",
      url_edit: "",
      storage: "",
      value:"",
      coins:[]
    };
  },
  created() {
    if (this.$store.getters["auth/isAdmin"]) {
      this.restaurant_id = this.$route.params ? this.$route.params.id : "";
      this.url_edit = `/tc-admin/restaurante/${this.restaurant_id}/administrar/carta/`;
    } else {
      this.restaurant_id = this.$store.getters["auth/restaurant"];
      this.url_edit = `/tc-admin/carta/`;
    }
    this.storage = process.env.VUE_APP_IMG_URL_API + "images/menu/";
    this.initCoins();
    this.getmenus();
  },
  methods: {
    initCoins(){
      const url = `api/restaurants/settings?restaurant_id=${this.restaurant_id}`;
      this.$api.get(url).then(res=>{
        this.coins = JSON.parse(res.data.data.settings.coins);
      });
    },
    async getmenus() {
      this.load_dish = true;
      this.menus = [];
      try {
        const { data } = await this.$api.get(
          `api/menus?restaurant_id=${this.restaurant_id}`
        );

        this.menus = data.data.menus;

        this.load_dish = false;
      } catch (error) {
        this.load_dish = false;
        this.snackbar = {
          text: "Lo sentimos, hubo un error al intentar obtener los usuarios",
          type: "error",
          active: true
        };
      }
    },
    async deletemenu(item) {
      const msj =
        "Se eliminaran permanentemente los datos del plato " + item.name.es;

      const confirm = await this.$root.$confirm(
        "¿Estas Seguro de Eliminar?",
        msj,
        {
          type: "delete",
          btnConfirmText: "Si, Eliminalo",
          btnCancelText: "Descartar"
        }
      );

      if (confirm) {
        this.load_dish = true;
        const url = "api/menus/" + item.id;
        try {
          const { data } = await this.$api.delete(url);
          this.load_dish = false;
          this.getmenus();
          this.search = "";
          this.$root.$snakbar({
            text: data.message,
            type: "success",
            active: true
          });
        } catch (error) {
          this.load_dish = false;
          if (error.response.status == "400") {
            this.$root.$snakbar({
              text: error.response.data.message,
              type: "error",
              active: true
            });
          } else {
            this.$root.$snakbar({
              text: "Lo sentimos, hubo un error al intentar eliminar el Plato.",
              type: "error",
              active: true
            });
          }
        }
      }
    },
    formatNumber(val, country){
      this.$numeral.locale(country)
      return this.$numeral(val).format('0,0[.]00 $')
    },
    filterPrice(price){
      var prices = Object.values(price)
      var exists = false;
      for (let a = 0; a < prices.length; a++) {
        const price = prices[a];
        for (let e = 0; e < this.coins.length; e++) {
          const coins = this.coins[e];
          exists = false;
          if (price.coin == coins.cod) {
            exists =  true;
            break;
          }
        }
        if (!exists) {
          prices.splice(a,1);
        }
      }
      return prices;
    }
  },
  computed: {
    filters_head() {
      return this.headers.filter(item => item.text != "");
    },
    statusCount() {
      return {
        public: this.menus.filter(item => item.visibility == 1).length,
        eraser: this.menus.filter(item => item.visibility == 0).length
      };
    },
    menus_filter() {
      return this.menus.filter(item => {
        if (this.filter_status == "todo") return true;

        return item.visibility == this.filter_status;
      });
    },
    coinSymbol() {
      return coinSymbol
    },
    coinCountry() {
      return coinCountry
    }
  }
};
</script>
<style scope>
.active-filter {
  font-weight: bold;
  color: #283145 !important;
  background: white !important;
}
.active-filter:before {
  background-color: transparent !important;
}
</style>
